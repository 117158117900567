import {useRef} from 'react'
import {ButtonColor} from '@common/ui/buttons/get-shared-button-style';
import {useSettings} from '@common/core/settings/use-settings';
import {useIsMobileMediaQuery} from '@common/utils/hooks/is-mobile-media-query';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {Menu, MenuTrigger} from '@common/ui/navigation/menu/menu-trigger';
import {IconButton} from '@common/ui/buttons/icon-button';
import {PersonIcon} from '@common/icons/material/Person';
import {Item} from '@common/ui/forms/listbox/item';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';
import {Button} from '@common/ui/buttons/button';
import {NavbarProps} from '@common/ui/navigation/navbar/navbar';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';



interface NavbarAuthButtonsProps {
  primaryButtonColor?: ButtonColor;
  navbarColor?: NavbarProps['color'];
}

const TawkMessenger = ({tawkMessengerRef, primaryButtonColor}:any) => {
  return (
    <TawkMessengerReact
      ref={tawkMessengerRef}
      propertyId="655a454fd600b968d314e706"
      widgetId="1hfk9tfvi"
      style={{color: primaryButtonColor}}
    />
  );
};



export function NavbarAuthButtons({
  primaryButtonColor,
  navbarColor,
}: NavbarAuthButtonsProps) {
  const {registration} = useSettings();
  const isMobile = useIsMobileMediaQuery();
  const navigate = useNavigate();

  const tawkMessengerRef = useRef<any>(null)

  if (!primaryButtonColor) {
    primaryButtonColor = navbarColor === 'primary' ? 'paper' : 'primary';
  }

  if (isMobile) {
    return (
      <div>
        <TawkMessenger tawkMessengerRef={tawkMessengerRef} primaryButtonColor={primaryButtonColor} />
        <MenuTrigger>
          <IconButton size="md">
            <PersonIcon />
          </IconButton>
          <Menu>
            <Item value="login" onSelected={() => navigate('/login')}>
              <Trans message="Login" />
            </Item>
            {!registration.disable && (
              <Item value="register" onSelected={() => navigate('/register')}>
                <Trans message="Register" />
              </Item>
            )}
            <div className="ms-5">
              <Item
                value="login"
                onClick={() => {
                  if (tawkMessengerRef.current !== null)
                    tawkMessengerRef.current.toggle();
                }}
              >
                <Trans message="Get Demo" />
              </Item>
            </div>
          </Menu>
        </MenuTrigger>
      </div>
    );
  }

  return (
    <div className="text-sm">
      <TawkMessenger
        tawkMessengerRef={tawkMessengerRef}
        primaryButtonColor={primaryButtonColor}
        pri
      />
      <Button
        elementType={Link}
        to="/pricing"
        variant="raised"
        color={primaryButtonColor}
        className="mr-10"
      >
        <Trans message="Pricing" />
      </Button>
      {!registration.disable && (
        <Button
          elementType={Link}
          to="/register"
          variant="raised"
          color={primaryButtonColor}
          className="mr-10"
        >
          <Trans message="Register" />
        </Button>
      )}
      <Button
        elementType={Link}
        to="/login"
        variant="raised"
        color={primaryButtonColor}
      >
        <Trans message="Login" />
      </Button>
      <button
        onClick={() => {
          if (tawkMessengerRef.current !== null)
            tawkMessengerRef.current.toggle();
        }}
        className="ml-20"
      >
        <Button elementType={Link} variant="raised" color={primaryButtonColor}>
          <Trans message="Get Demo" />
        </Button>
      </button>
    </div>
  );
}
