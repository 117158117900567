import {Link, Navigate, useLocation, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '../../ui/forms/input-field/text-field/text-field';
import {Button} from '../../ui/buttons/button';
import {Form} from '../../ui/forms/form';
import {LinkStyle} from '../../ui/buttons/external-link';
import {RegisterPayload, useRegister} from '../requests/use-register';
import {SocialAuthSection} from './social-auth-section';
import {AuthLayout} from './auth-layout/auth-layout';
import {Trans} from '../../i18n/trans';
import {FormCheckbox} from '../../ui/forms/toggle/checkbox';
import {CustomMenuItem} from '../../menus/custom-menu';
import {useRecaptcha} from '../../recaptcha/use-recaptcha';
import {StaticPageTitle} from '../../seo/static-page-title';
import {useSettings} from '../../core/settings/use-settings';
import { useEffect } from 'react';

export function RegisterPage() {
  const {
    branding,
    registration: {disable},
    social,
  } = useSettings();
  const {verify, isVerifying} = useRecaptcha('register');

  const {pathname} = useLocation();
  const [searchParams] = useSearchParams();

  const isWorkspaceRegister = pathname.includes('workspace');
  const isBillingRegister = searchParams.get('redirectFrom') === 'pricing';
  const searchParamsEmail = searchParams.get('email') || undefined;

  const form = useForm<RegisterPayload>({
    defaultValues: {email: searchParamsEmail},
  });
  const register = useRegister(form);

  if (disable) {
    return <Navigate to="/login" replace />;
  }

  let heading = <Trans message="Create a new account" />;
  if (isWorkspaceRegister) {
    heading = (
      <Trans
        values={{siteName: branding?.site_name}}
        message="To join your team on :siteName, create an account"
      />
    );
  } else if (isBillingRegister) {
    heading = <Trans message="First, let's create your account" />;
  }

  const message = (
    <Trans
      values={{
        a: parts => (
          <Link className={LinkStyle} to="/login">
            {parts}
          </Link>
        ),
      }}
      message="Already have an account? <a>Sign in.</a>"
    />
  );

  return (
    <AuthLayout heading={heading} message={message}>
      <StaticPageTitle>
        <Trans message="Sign Up" />
      </StaticPageTitle>
      <Form
        form={form}
        onSubmit={async payload => {
          const isValid = await verify();
          if (isValid) {
            register.mutate(payload);
          }
        }}
      >
        <div className="grid grid-cols-2 gap-x-5 mb-16">
          <FormTextField
            className=""
            name="first_name"
            placeholder="First Name"
            type="text"
            disabled={!!searchParamsEmail}
            label={<Trans message="First Name" />}
            required
          />
          <FormTextField
            className=""
            name="last_name"
            placeholder="Last Name"
            type="text"
            disabled={!!searchParamsEmail}
            label={<Trans message="Last Name" />}
            required
          />
        </div>
        <div className="grid grid-cols-2 gap-x-5 mb-16">
          <FormTextField
            className=""
            name="email"
            type="email"
            placeholder="Email Address"
            disabled={!!searchParamsEmail}
            label={<Trans message="Email Address" />}
            required
          />
          <FormTextField
            className=""
            name="phone"
            type="number"
            placeholder="Phone"
            disabled={!!searchParamsEmail}
            label={<Trans message="Phone" />}
            required
          />
        </div>
        <FormTextField
          className="mb-20"
          name="company_name"
          type="text"
          placeholder="Company Name"
          disabled={!!searchParamsEmail}
          label={<Trans message="Company Name" />}
          required
        />
        <FormTextField
          className="mb-20"
          name="domain"
          placeholder='Your Domain'
          type="text"
          disabled={!!searchParamsEmail}
          label={<Trans message="Your domain (yourdomain.receptackle.com)" />}
          required
        />
        <div className="grid grid-cols-2 gap-x-5 mb-32">
          <FormTextField
            name="username"
            type="username"
            placeholder="Username"
            label={<Trans message="Username" />}
            required
          />
          <FormTextField
            name="password"
            type="password"
            placeholder="Password"
            label={<Trans message="Password" />}
            required
          />
        </div>
        {/* This policy check boxes was in main project code */}
        {/* <PolicyCheckboxes /> */}
        {/* Here i am adding own custom policy check boxes */}
        <CustomPrivacyPolicyBoxes />
        <Button
          className="block w-full"
          type="submit"
          variant="flat"
          color="primary"
          size="md"
          disabled={register.isLoading || isVerifying}
        >
          <Trans message="Create account" />
        </Button>
        <SocialAuthSection
          dividerMessage={
            social.compact_buttons ? (
              <Trans message="Or sign up with" />
            ) : (
              <Trans message="OR" />
            )
          }
        />
      </Form>
    </AuthLayout>
  );
}

// custom privacy policy check boxes

const CustomPrivacyPolicyBoxes = () => {
  return (
    <div className="mb-20">
      <FormCheckbox
        key="policy-1"
        name="privacy_policy"
        className="block mb-4"
        required
      >
        <Trans
          message="I agree to Receptackle's :termsOfService and :privacyPolicy"
          values={{
            termsOfService: (
              <CustomMenuItem
                unstyled
                className={() => LinkStyle}
                item={{
                  action: '/pages/terms-of-service',
                  type: 'route',
                  id: 'privacy-1',
                  label: 'Terms of Service',
                  order: 0,
                }}
              />
            ),
            privacyPolicy: (
              <CustomMenuItem
                unstyled
                className={() => LinkStyle}
                item={{
                  action: '/pages/privacy-policy',
                  type: 'route',
                  id: 'privacy-1',
                  label: 'Privacy Policy',
                  order: 0,
                }}
              />
            ),
          }}
        />
      </FormCheckbox>
      <FormCheckbox
        key="policy-2"
        name="stay_updated"
        className="block mb-4"
        required
      >
        <Trans message="I would like to be updated on news, offers and training." />
      </FormCheckbox>
    </div>
  );
}

function PolicyCheckboxes() {
  const {
    registration: {policies},
  } = useSettings();

  if (!policies) return null;

  return (
    <div className="mb-32">
      {policies.map(policy => (
        <FormCheckbox
          key={policy.id}
          name={policy.id}
          className="block mb-4"
          required
        >
          <Trans
            message="I accept the :name"
            values={{
              name: (
                <CustomMenuItem
                  unstyled
                  className={() => LinkStyle}
                  item={policy}
                />
              ),
            }}
          />
        </FormCheckbox>
      ))}
    </div>
  );
}
